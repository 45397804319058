import { Spin } from "antd";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PageContainer, Title } from "../../common/components/StyledElements";
import APIConstants from "../../common/constants/APIConstants";
import { api } from "../../common/utils/APIMethods";

const Container = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const PickersListContainer = styled.div`
  width: 60%;
  height: 100vh;
  padding: 20px;
  overflow-y: scroll;
`;
const PickerItem = styled.div`
  padding: 10px 30px;
  margin: 1em;
  border-radius: 10px;
  font-size: 21px;
  font-weight: 600;
  background-color: white;
  box-shadow: 1px 1px 2px lightgray;
`;

const Picker = () => {
  const navigate = useNavigate();

  const { isLoading, data: pickersList = [] } = useQuery("pickersList", () =>
    api({ url: APIConstants.pickersURL })
  );

  const handlePickerSelection = (selectedPicker) => {
    navigate("invoice", {
      state: {
        selectedPicker: selectedPicker,
      },
    });
  };

  return (
    <Container>
      <Title>Select Picker</Title>
      <PickersListContainer>
        {isLoading ? (
          <Spin />
        ) : (
          pickersList.map((picker) => (
            <PickerItem
              key={picker.pickerId}
              onClick={() => handlePickerSelection(picker)}
            >
              {picker.name}
            </PickerItem>
          ))
        )}
      </PickersListContainer>
    </Container>
  );
};

export default Picker;
