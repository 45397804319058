import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PageContainer } from "../common/components/StyledElements";

const DashboardContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const StyledButton = styled(Button)`
  height: 60px;
  width: 25%;
  margin-bottom: 20px;
  font-size: large;
  font-weight: 600;
`;

const Dashboard = () => {
  const navigate = useNavigate();

  return (
    <DashboardContainer>
      <StyledButton
        type="primary"
        size="large"
        onClick={() => navigate("/biller")}
      >
        Biller
      </StyledButton>
      <StyledButton
        type="primary"
        size="large"
        onClick={() => navigate("/picker")}
      >
        Picker
      </StyledButton>
      <StyledButton
        type="primary"
        size="large"
        onClick={() => navigate("/checker")}
      >
        Checker
      </StyledButton>
    </DashboardContainer>
  );
};

export default Dashboard;
