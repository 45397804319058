const COLORS = {
  primary_green: "#00BFB8",
  primary_blue: "#f3fcff",
  primary_grey: "#d4d4d4",
  disabled_grey: "#EDEDED",
  yellow: "#FFBB57",
  action_approve: "#00a400",
  action_decline: "#F93549",
  action_review: "#F9A435",
};

export default COLORS;
