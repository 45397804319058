import { Button, message, Spin } from "antd";
import { useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  SearchInput,
  PageContainer,
  Title,
} from "../../common/components/StyledElements";
import APIConstants from "../../common/constants/APIConstants";
import { api } from "../../common/utils/APIMethods";

const Container = styled(PageContainer)`
  display: flex;
  align-items: center;
`;
const InvoiceListContainer = styled.div`
  width: 60%;
  height: 100vh;
  padding: 20px;
  overflow-y: scroll;
`;
const InvoiceItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  margin: 1em;
  border-radius: 10px;
  font-size: 21px;
  font-weight: 600;
  background-color: white;
  box-shadow: 1px 1px 2px lightgray;
`;
const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
const StyledSearchInput = styled(SearchInput)`
  margin-left: 10%;
`;
const EmptyText = styled.div`
  text-align: center;
`;

const Invoices = () => {
  const navigate = useNavigate();
  const locationProps = useLocation();
  const { selectedPicker = {} } = locationProps?.state;
  const [searchText, setSearchText] = useState("");

  const {
    isLoading,
    data: invoicesList = [],
    refetch,
  } = useQuery("invoicesList", () => api({ url: APIConstants.invoiceURL }));

  const filteredInvoices = useMemo(
    () =>
      invoicesList.filter((invoice) =>
        invoice.invoiceNumber.toLowerCase().includes(searchText.toLowerCase())
      ),
    [invoicesList, searchText]
  );

  const pendingInvoicesList = filteredInvoices.filter((invoice) => {
    const pagesData = Object.values(invoice.pages);
    const isNotStarted = pagesData.find((page) => page.startTime === null);
    if (isNotStarted) {
      return invoice;
    }
    return false;
  });

  const pickingInvoicesBySelectedPicker = invoicesList.filter((invoice) => {
    const pagesData = Object.values(invoice.pages);
    const hasPendingInvoice = pagesData.find(
      (page) =>
        page.pickerId === selectedPicker.pickerId && page.endTime === null
    );
    if (hasPendingInvoice) {
      return invoice;
    }
    return false;
  });

  const handlePagesSelection = (selectedInvoice) => {
    navigate("pages", {
      state: {
        selectedInvoice: selectedInvoice,
        selectedPicker: selectedPicker,
      },
    });
  };

  const endPickingMutation = useMutation(
    (body) =>
      api({
        url: APIConstants.invoiceEndURL,
        method: "POST",
        body: body,
      }),
    {
      onSuccess: (response) => {
        message.success(
          response.message ||
            `${selectedPicker.name} is successfully picked all products`
        );
        refetch();
      },
    }
  );

  const handleEndPicking = (invoice) => {
    const pages = invoice.pages;
    const pageNumbers = [];
    Object.keys(pages).forEach((page) => {
      const pageObj = pages[page];
      if (
        pageObj.pickerId === selectedPicker.pickerId &&
        pageObj.endTime === null
      ) {
        pageNumbers.push(parseInt(page, 10));
      }
    });

    endPickingMutation.mutate({
      pages: pageNumbers,
      pickerId: selectedPicker?.pickerId,
      invoiceId: invoice?.id,
    });
  };

  return (
    <PageContainer>
      <Spin size="large" spinning={isLoading || endPickingMutation.isLoading}>
        <HeadingContainer>
          <Title>All Invoices</Title>
          <Title>Picking Invoices</Title>
        </HeadingContainer>
        <StyledSearchInput
          placeholder="Search Invoice"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
        <Container>
          <InvoiceListContainer>
            {pendingInvoicesList?.length > 0 ? (
              pendingInvoicesList.map((invoice) => (
                <InvoiceItem
                  key={invoice.id}
                  onClick={() => handlePagesSelection(invoice)}
                >
                  {invoice.invoiceNumber}
                </InvoiceItem>
              ))
            ) : (
              <EmptyText>No Invoices Available To Pick</EmptyText>
            )}
          </InvoiceListContainer>
          <InvoiceListContainer>
            {pickingInvoicesBySelectedPicker?.length > 0 ? (
              pickingInvoicesBySelectedPicker.map((invoice) => (
                <InvoiceItem key={invoice.id}>
                  <div>{invoice.invoiceNumber}</div>
                  <Button
                    type="primary"
                    loading={endPickingMutation.isLoading}
                    onClick={() => handleEndPicking(invoice)}
                  >
                    End
                  </Button>
                </InvoiceItem>
              ))
            ) : (
              <EmptyText>No Picking Invoices Available</EmptyText>
            )}
          </InvoiceListContainer>
        </Container>
      </Spin>
    </PageContainer>
  );
};

export default Invoices;
