import { Button, Input, message } from "antd";
import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  PageContainer,
  ImageInputElement,
  Title,
} from "../../common/components/StyledElements";
import APIConstants from "../../common/constants/APIConstants";
import COLORS from "../../common/constants/Colors";
import { api } from "../../common/utils/APIMethods";

const Container = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const ContentContainer = styled.div`
  padding: 30px;
  border-radius: 10px;
  width: 25%;
  background-color: white;
`;
const InputContainer = styled.div`
  margin-top: 10px;
`;
const TitleElement = styled.div`
  font-size: 16px;
  font-weight: bold;
`;
const InputElement = styled(Input)`
  height: 40px;
`;
const InputFileLabel = styled.label`
  border: 1px solid ${COLORS.primary_green};
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  color: ${COLORS.primary_green};
  font-weight: bold;
`;
const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const StyledButton = styled(Button)`
  height: 50px;
  margin-top: 10px;
  width: 150px;
  font-size: large;
`;

const Biller = () => {
  const navigate = useNavigate();
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [file, setFile] = useState(null);

  const uploadInvoice = async (body) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/pdf");

    await api({
      url: `${APIConstants.invoiceURL}/${invoiceNumber}`,
      method: "POST",
      requestHeaders: myHeaders,
      body: body,
      isFormData: true,
    });
  };

  const invoiceFileUploadMutation = useMutation((body) => uploadInvoice(body), {
    onSuccess: (response) => {
      setFile(null);
      setInvoiceNumber("");
      navigate("/", { replace: true });
      message.success(response.message || "Invoice file uploaded successfully");
    },
  });

  const handleSubmit = () => {
    invoiceFileUploadMutation.mutate(file.file);
  };

  return (
    <Container>
      <ContentContainer>
        <Title>Upload Invoice</Title>
        <ImageContainer>
          <InputFileLabel htmlFor="fileUpload">Upload File</InputFileLabel>
          <ImageInputElement
            id="fileUpload"
            type="file"
            style={{ display: "none" }}
            accept="application/pdf,application/vnd.ms-excel"
            placeholder="Upload File"
            onChange={(e) => {
              setFile({
                name: e.target?.files[0]?.name,
                image: URL.createObjectURL(e.target?.files[0]),
                file: e.target?.files[0],
              });
            }}
          />
          {file && (
            <div>
              <h5>{file.name}</h5>
              <Button
                type="danger"
                onClick={() => {
                  setFile(null);
                }}
              >
                Remove File
              </Button>
            </div>
          )}
        </ImageContainer>
        <InputContainer>
          <TitleElement>Invoice OR Summary Number</TitleElement>
          <InputElement
            value={invoiceNumber}
            onChange={(e) => setInvoiceNumber(e.target.value)}
          />
        </InputContainer>
      </ContentContainer>
      <StyledButton
        type="primary"
        size="small"
        disabled={!invoiceNumber}
        onClick={handleSubmit}
        loading={invoiceFileUploadMutation.isLoading}
      >
        Submit
      </StyledButton>
    </Container>
  );
};

export default Biller;
