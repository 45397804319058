const baseURL =
  "https://jc1bbj1kib.execute-api.ap-southeast-1.amazonaws.com/staging/";
const pickersURL = `${baseURL}picker`;
const invoiceURL = `${baseURL}invoice`;
const invoiceStartURL = `${baseURL}invoice/start`;
const invoiceEndURL = `${baseURL}invoice/end`;
const invoicePickedURL = `${baseURL}invoice/picked`;
const invoiceCheckerStartURL = `${baseURL}invoice/checker-start`;
const invoiceCheckerEndURL = `${baseURL}invoice/checker-end`;

const APIConstants = {
  pickersURL,
  invoiceURL,
  invoiceStartURL,
  invoiceEndURL,
  invoicePickedURL,
  invoiceCheckerStartURL,
  invoiceCheckerEndURL,
};

export default APIConstants;
