import styled, { css } from "styled-components";
import COLORS from "../constants/Colors";

export const PageContainer = styled.div`
  height: 100vh;
  background-color: ${COLORS.primary_blue};
`;

export const ImageInputElement = styled.input`
  display: flex;
  flex-wrap: wrap;
  height: 68px;
  width: 68px;
  border: 1.5px solid ${COLORS.primary_green};
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 10px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: ${COLORS.disabled_grey};
      border-color: ${COLORS.disabled_grey};
    `}
`;

export const Title = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

export const SearchInput = styled.input`
  color: ${COLORS.primary_green};
  ::placeholder {
    color: ${COLORS.primary_green};
  }
  border: 1px solid ${COLORS.primary_green};
  border-radius: 4px;
  padding: 10px;
  width: 30%;
  height: 35px;
  &:focus {
    border: 2px solid ${COLORS.primary_green} !important;
    outline: none;
  }
`;
