import { Button, Checkbox, message } from "antd";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PageContainer } from "../../common/components/StyledElements";
import { api } from "../../common/utils/APIMethods";
import APIConstants from "../../common/constants/APIConstants";

const Container = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const PagesGridContainer = styled.div`
  width: 60%;
  height: 100vh;
  padding: 20px;
  overflow-y: scroll;
`;
const InvoicePageContainer = styled.div`
  padding: 15px 30px;
  margin: 1em;
  border-radius: 10px;
  background-color: white;
  justify-content: center;
  box-shadow: 1px 1px 2px lightgray;
`;
const StyledButton = styled(Button)`
  width: 150px;
  height: 60px;
  margin: 1em;
`;

const InvoicePages = () => {
  const navigate = useNavigate();
  const locationProps = useLocation();
  const { selectedInvoice = {}, selectedPicker = {} } =
    locationProps?.state || {};
  const [allPages, setAllPages] = useState({});

  const preparePagesData = () => {
    const pages = selectedInvoice.pages;
    let updatedPages = {};
    Object.keys(pages).forEach((page) => {
      updatedPages = {
        ...updatedPages,
        [page]: {
          ...pages[page],
          id: page,
          selected: pages[page].pickerId ? true : false,
          disabled: pages[page].pickerId,
        },
      };
    });

    setAllPages(updatedPages);
  };

  useEffect(() => {
    preparePagesData();
  }, []);

  const handleSelection = (value, page) => {
    const updatedPages = {
      ...allPages,
      [page]: {
        ...allPages[page],
        selected: value,
      },
    };
    setAllPages(updatedPages);
  };

  const startPickMutation = useMutation(
    (body) =>
      api({
        url: APIConstants.invoiceStartURL,
        method: "POST",
        body: body,
      }),
    {
      onSuccess: (response) => {
        message.success(
          response.message ||
            `${selectedPicker.name} is started picking products`
        );
        navigate(-1);
      },
    }
  );

  const handleStartPicking = () => {
    const pageNumbers = [];
    Object.values(allPages).forEach((page) => {
      if (!page.disabled && page.selected) {
        pageNumbers.push(parseInt(page.id, 10));
      }
    });

    startPickMutation.mutate({
      pages: pageNumbers,
      pickerId: selectedPicker?.pickerId,
      invoiceId: selectedInvoice?.id,
    });
  };

  return (
    <Container>
      <PagesGridContainer>
        {Object.keys(allPages).map((page) => {
          const { selected, disabled, id: key } = allPages[page];
          return (
            <InvoicePageContainer
              onClick={() => handleSelection(!selected, page)}
            >
              <Checkbox key={key} checked={selected} disabled={disabled}>
                Page {page}
              </Checkbox>
            </InvoicePageContainer>
          );
        })}
      </PagesGridContainer>
      <StyledButton
        type="primary"
        size="large"
        loading={startPickMutation.isLoading}
        onClick={handleStartPicking}
      >
        Start
      </StyledButton>
    </Container>
  );
};

export default InvoicePages;
