import { Suspense } from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "routes";
import Biller from "routes/Biller";
import Checker from "routes/Checker";
import Picker from "routes/Picker";
import InvoicePages from "routes/Picker/InvoicePages";
import Invoices from "routes/Picker/Invoices";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Suspense fallback={<div>Loading your content...</div>}>
          <Routes>
            <Route index path="/" element={<Dashboard />} />
            <Route path="biller" element={<Biller />} />
            <Route path="picker" element={<Picker />} />
            <Route path="picker/invoice" element={<Invoices />} />
            <Route path="picker/invoice/pages" element={<InvoicePages />} />
            <Route path="checker" element={<Checker />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
