import { Button, message, Spin } from "antd";
import { useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import {
  SearchInput,
  PageContainer,
  Title,
} from "../../common/components/StyledElements";
import APIConstants from "../../common/constants/APIConstants";
import { api } from "../../common/utils/APIMethods";

const Container = styled(PageContainer)`
  display: flex;
  align-items: center;
`;
const InvoiceListContainer = styled.div`
  width: 60%;
  height: 100vh;
  padding: 20px;
  overflow-y: scroll;
`;
const InvoiceItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  margin: 1em;
  border-radius: 10px;
  font-size: 21px;
  font-weight: 600;
  background-color: white;
  box-shadow: 1px 1px 2px lightgray;
`;
const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
const StyledSearchInput = styled(SearchInput)`
  margin-left: 10%;
`;
const EmptyText = styled.div`
  text-align: center;
`;

const Checker = () => {
  const [searchText, setSearchText] = useState("");

  const {
    isLoading,
    data: invoicesList = [],
    refetch,
  } = useQuery("invoicePickedList", () =>
    api({ url: APIConstants.invoicePickedURL })
  );

  const checkingNotStartedInvoices = useMemo(
    () => invoicesList.filter((invoice) => invoice.checkerStartTime === null),
    [invoicesList]
  );

  const filteredInvoices = useMemo(
    () =>
      checkingNotStartedInvoices.filter((invoice) =>
        invoice.invoiceNumber.toLowerCase().includes(searchText.toLowerCase())
      ),
    [checkingNotStartedInvoices, searchText]
  );

  const checkingStartedInvoices = useMemo(
    () => invoicesList.filter((invoice) => invoice.checkerStartTime !== null),
    [invoicesList]
  );

  const checkerMutation = useMutation(
    (body) =>
      api({
        url: body.url,
        method: "POST",
        body: body.data,
      }),
    {
      onSuccess: (response) => {
        message.success(response.message);
        refetch();
      },
    }
  );

  const handleStartChecking = (invoice) => {
    checkerMutation.mutate({
      data: { invoiceId: invoice?.id },
      url: APIConstants.invoiceCheckerStartURL,
    });
  };

  const handleEndChecking = (invoice) => {
    checkerMutation.mutate({
      data: { invoiceId: invoice?.id },
      url: APIConstants.invoiceCheckerEndURL,
    });
  };

  return (
    <PageContainer>
      <Spin size="large" spinning={checkerMutation.isLoading || isLoading}>
        <HeadingContainer>
          <Title>All Invoices</Title>
          <Title>Checking Invoices</Title>
        </HeadingContainer>
        <StyledSearchInput
          placeholder="Search Invoice"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
        <Container>
          <InvoiceListContainer>
            {filteredInvoices?.length > 0 ? (
              filteredInvoices.map((invoice) => (
                <InvoiceItem key={invoice.id}>
                  <div>{invoice.invoiceNumber}</div>
                  <Button
                    type="primary"
                    onClick={() => handleStartChecking(invoice)}
                  >
                    Start
                  </Button>
                </InvoiceItem>
              ))
            ) : (
              <EmptyText>No Invoices Available To Check</EmptyText>
            )}
          </InvoiceListContainer>
          <InvoiceListContainer>
            {checkingStartedInvoices?.length > 0 ? (
              checkingStartedInvoices.map((invoice) => (
                <InvoiceItem key={invoice.id}>
                  <div>{invoice.invoiceNumber}</div>
                  <Button
                    type="primary"
                    onClick={() => handleEndChecking(invoice)}
                  >
                    End
                  </Button>
                </InvoiceItem>
              ))
            ) : (
              <EmptyText>No Checking Invoices Available</EmptyText>
            )}
          </InvoiceListContainer>
        </Container>
      </Spin>
    </PageContainer>
  );
};

export default Checker;
